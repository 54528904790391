<style lang="scss" scoped>
.ui-left {
  width: 1071px;
  position: absolute;
  top: 109px;
  left: 20px;
  z-index: 4;
  .topchart {
    width: 360px;
    margin-bottom: 24px;
  }
  img {
    width: 100%;
  }
  .left-content {
    position: relative;
  }
  .content-table {
    pointer-events: all;
    position: absolute;
    width: 1039px;
    height: 518px;
    top: 116px;
    left: 16px;
    padding-top: 16px;
    border-top: 1px solid rgba(82, 87, 208, 0.6);
    .table-item {
      width: 100%;
      height: 43px;
      border-radius: 4px;
      background: rgba(0, 0, 0, 0.12);
      padding: 8px 16px;
      display: flex;
      align-items: center;
      position: relative;
      .icon-head {
        display: none;
        position: absolute;
        width: 16px;
        top: 13px;
        height: 21px;
        left: -6px;
      }
      .cricle {
        width: 8px;
        height: 8px;
        border-radius: 50%;
      }
      .cricle1 {
        background: #ffa928;
      }
      .cricle2 {
        background: #50ffe1;
      }
      .text-1 {
        width: 82px;
        margin-left: 8px;
        margin-right: 8px;
      }
      .text-2 {
        width: 166px;
        text-align: center;
        margin-right: 24px;
      }
      .text-3 {
        width: 438px;
        margin-right: 24px;
      }
      .text-4 {
        width: 160px;
        text-align: center;
        margin-right: 24px;
      }
      .text-content {
        color: #a0a8ff;
        font-size: 16px;
        font-weight: 500;
      }
      .text-content2 {
        color: rgba(255, 255, 255, 0.6);
        font-size: 14px;
        font-weight: 500;
      }
      .line {
        width: 1px;
        height: 16px;
        border-radius: 2px;
        background: rgba(255, 255, 255, 0.3);
        margin-right: 8px;
      }
    }
    .select-item {
      background: linear-gradient(90deg, #5257d0 0%, #393b71 100%);
      .icon-head {
        display: block;
      }
    }
  }
}
.status {
  width: 51px;
  height: 21px;
  color: #fff;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.status1 {
  border-radius: 4px;
  border: 1px solid #ffa928;
  background: rgba(255, 169, 40, 0.25);
  box-shadow: 0px 0px 8px 0px #ffa928 inset;
}
.status2 {
  border-radius: 4px;
  border: 1px solid #76ffe9;
  background: rgba(43, 178, 189, 0.5);
  box-shadow: 0px 0px 8px 0px #22ffd7 inset;
}
.dialog {
  position: absolute;
  right: 394px;
  width: 280px;
  top: 112px;
  z-index: 100;
  .dialog-top {
    width: 100%;
    height: 28px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .dialog-body {
    background-color: #1b1d35cc;
    width: 100%;
    padding: 0 8px;
    padding-top: 16px;
    padding-bottom: 24px;
    img {
      width: 100%;
      height: 100%;
    }
    .sub-title {
      width: 100%;
      height: 24px;
    }
    .sb-list {
      width: 248px;
      padding-left: 8px;
      margin-bottom: 16px;
      .list-item {
        width: 100%;
        height: 28px;
        border-radius: 4px;
        background: rgba(0, 0, 0, 0.12);
        margin-top: 8px;
        padding: 4px 8px;
        display: flex;
        .left-text {
          text-align: justify;
          text-align-last: justify;
          width: 56px;
          font-size: 14px;
          font-weight: 500;
          color: #ffffff99;
          margin-right: 16px;
        }
        .right-text {
          font-size: 14px;
          font-weight: 500;
          color: #ffffff;
        }
        .right-status,
        .err-status {
          width: 40px;
          height: 22px;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          color: #fff;
        }
        .right-status {
          border: 1px solid #76ffe9;
          background: rgba(43, 178, 189, 0.5);
          box-shadow: 0px 0px 8px 0px #22ffd7 inset;
        }
        .err-status {
          border: 1px solid #fda8a3;
          background: rgba(255, 99, 89, 0.5);
          box-shadow: 0px 0px 8px 0px #ff6359 inset;
        }
        .right-center {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .content {
      margin-top: 10px;
      width: 248px;
    }
  }
}
.b1 {
  left: 260px;
  top: 90px;
}

.b2 {
  left: 260px;
  top: 130px;
}
.ui-right{
  position: fixed;
  right:20px;
  top:109px;
width: 547px;
z-index: 4;
  img{
    width: 100%;
  }
}
</style>

<template>
  <div class="index">
    <div class="ui-left" v-rc:left>
      <div class="nb b1" @click="show('normal')"></div>
      <div class="nb b2" @click="show('warning')"></div>
      <div class="topchart"><img src="./top-chart.png" alt="" /></div>
      <!-- <div class="left-content">
        <img src="./left-content.png" alt="" />
        <div class="content-table">
          <div
            class="table-item"
            v-for="(item, index) in list"
            :class="{ 'select-item': selectIndex == index }"
            @click="openDialog(item, index)"
          >
            <div class="icon-head">
              <img src="@/assets/icon-head.png" alt="" />
            </div>
            <div class="cricle" :class="[setStatus(item[4])[2]]"></div>
            <div class="text-1 text-content">{{ item[0] }}</div>
            <div class="line"></div>
            <div class="text-2 text-content">{{ item[1] }}</div>
            <div class="text-content2 text-3">
              {{ item[2] }}
            </div>
            <div class="text-content2 text-4">{{ item[3] }}</div>
            <div class="status" :class="[setStatus(item[4])[1]]">
              {{ setStatus(item[4])[0] }}
            </div>
          </div>
        </div>
      </div> -->
    </div>
    <div class="dialog" v-if="selectItem">
      <div class="dialog-top">
        <img src="@/assets/err-top.png" alt="" />
      </div>
      <div class="dialog-body">
        <div class="sub-title">
          <img src="@/assets/sub-sb.png" alt="" />
        </div>
        <div class="sb-list">
          <div class="list-item">
            <div class="left-text">设备编码</div>
            <div class="right-text">D9:3C:28:8F:41:53</div>
          </div>
          <div class="list-item">
            <div class="left-text">设备位置</div>
            <div class="right-text">B1</div>
          </div>
          <div class="list-item">
            <div class="left-text">设备状态</div>
            <div :class="[setStatus(selectItem[4])[4]]">
              {{ setStatus(selectItem[4])[3] }}
            </div>
          </div>
          <div class="list-item">
            <div class="left-text">负责人</div>
            <div class="right-text">郑盈</div>
          </div>
          <div class="list-item">
            <div class="left-text">维修状态</div>
            <div class="status" :class="[setStatus(selectItem[4])[1]]">
              {{ setStatus(selectItem[4])[0] }}
            </div>
          </div>
        </div>
        <div class="sub-title">
          <img src="@/assets/sub-wx.png" alt="" />
        </div>
        <div class="content">
          <img src="./content.png" alt="" />
        </div>
      </div>
    </div>
    <div class="ui-right">
      <img src="./ui-right.png" alt="">
    </div>
  </div>
</template>

<script>
import bus from "@/lib/bus";
export default {
  data() {
    return {
      selectItem: null,
      selectIndex: null,
      list: [
        [
          "变配电",
          "BM120879612576",
          "设备发生故障，已提交维修工单，请联系维保人",
          "2022/12/20    15:30",
          0,
        ],
        [
          "充电桩",
          "BM120879612576",
          "充电桩无信号传输，无法读取数据，请派人查看",
          "2022/12/20    15:30",
          0,
        ],
        [
          "场地监控",
          "BM120879612576",
          "监控画面卡死，需重启设备",
          "2022/12/20    15:30",
          1,
        ],
        [
          "道闸",
          "BM120879612576",
          "设备发生故障，已提交维修工单，请联系维保人",
          "2022/12/20    15:30",
          1,
        ],
        [
          "场地监控",
          "BM120879612576",
          "设备发生故障，已提交维修工单，请联系维保人",
          "2022/12/20    15:30",
          1,
        ],
        [
          "变配电",
          "BM120879612576",
          "设备发生故障，已提交维修工单，请联系维保人",
          "2022/12/20    15:30",
          1,
        ],
        [
          "变配电",
          "BM120879612576",
          "设备发生故障，已提交维修工单，请联系维保人",
          "2022/12/20    15:30",
          1,
        ],
        [
          "环境质量",
          "BM120879612576",
          "设备发生故障，已提交维修工单，请联系维保人",
          "2022/12/20    15:30",
          1,
        ],
        [
          "变配电",
          "BM120879612576",
          "设备发生故障，已提交维修工单，请联系维保人",
          "2022/12/20    15:30",
          1,
        ],
        [
          "环境质量",
          "BM120879612576",
          "设备发生故障，已提交维修工单，请联系维保人",
          "2022/12/20    15:30",
          1,
        ],
      ],
    };
  },
  mounted(){
   bus.$emit("set-sprite-display", "warning", 'all', true);
  },
  methods: {
    show(name) {
             bus.$emit("sceneRunning1",true)
      bus.$emit("set-sprite-display", "warning", name, true);
    },
    showtotat() {
      bus.$emit("totat");
    },
    openDialog(item, index) {
      if (this.selectIndex == index) {
        this.selectIndex = null;
        this.selectItem = null;
        return;
      }
      this.selectItem = item;
      this.selectIndex = index;
    },
    setStatus(status) {
      if (status == 0) {
        return ["未处理", "status1", "cricle1", "故障", "err-status"];
      } else {
        return ["已处理", "status2", "cricle2", "正常", "right-status"];
      }
    },
  },
};
</script>
